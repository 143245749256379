import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FaGithub } from 'react-icons/fa';
import '../App.css';

let project_titles = ['Police Case Analysis Tool', 'Wordguesser', 'Encrypted Dropbox', 'LockManager: Multigranular Locking System', 'Simplified Git'];
let project_descriptions = ['Tool to help journalists parse through police case files and identify key info. AWS Textract for OCR (optical character recognition) and spaCy for NER (identifying key entities).', 
    'My version of the classic Wordle game. Used Ruby on Rails to implement MVC framework for project. Managed state with PostgreSQL + cookies, and used Bootstrap for frontend.',
    'File-sharing system allowing users to perform CRUD operations on files and share/revoke access to other users. Used digital signatures, HMACs, block cipher modes, and RSA.',
    'Created and optimized a Java DBMS to process multi-threaded queries. Leveraged different lock types and 2-phase locking to ensure serializability and prevent deadlocks.',
    'Created version-control system to support 12 commands (init, add, commit, merge, branch, checkout, etc.). Wrote design document and coded several classes to represent local file structure with persistence.'
];
let project_links = ['', '', '', '', ''];

export const Projects = () => {
    return (
        <div className="jumbotron min-vh-100">
            <Container>
                <Row className='justify-content-left'>
                    <Col>
                        <h1>Projects</h1>
                        <p>Here are some of my favorite projects I've worked on and the link to my 
                            <a href="https://github.com/pruthvi-innamuri" target="_blank" rel="noopener noreferrer" style={{color: '#666', marginLeft: '0.3rem'}}>
                                Github
                            </a>.
                        </p>
                    </Col>
                </Row>
                <Row className='d-flex' >
                {
                    project_titles.map((title, i) => (
                        <Col key={i} xs={12} sm={12} md={6} lg={4}>
                            <Card style={{ width: '100%' }} className="project-card mt-4 mb-4 mr-4">
                                <Card.Body className="project-card-body">
                                    <Card.Title>{title}</Card.Title>
                                    <Card.Text>
                                        {project_descriptions[i]}
                                    </Card.Text>

                                    {project_links[i] !== '' && 
                                        <Button variant="primary" className='card-button'>
                                            <a href={project_links[i]} target="_blank" rel="noopener noreferrer" className='no-underline d-flex align-items-center' style={{color: '#fff'}}>
                                                <FaGithub style={{ marginRight: '0.3rem' }}/> Github
                                            </a>
                                        </Button>
                                    }           
                                </Card.Body>                 
                            </Card>
                        </Col>
                    ))
                }
                </Row>
            </Container>
            
        </div>
    );
};