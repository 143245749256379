import { Container, Navbar, NavDropdown, Nav, HashLink } from 'react-bootstrap';
import { FaHome, FaMusic, FaCode } from "react-icons/fa";
import { IoShareSocial } from "react-icons/io5";



export const Navigation = ({ homeRef, projectsRef, musicRef, contactRef }) => {

  function scrollToRef(ref) {
    console.log(ref);
    ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
  }


  return (

    <Nav className="flex-column nav-link-custom" style={{ position: 'fixed' }}>
        <Nav.Link className="home-nav-link" onClick={() => scrollToRef(homeRef)}>
          <FaHome size={40}/>
        </Nav.Link>
        <Nav.Link className="projects-nav-link" onClick={() => scrollToRef(projectsRef)}>
          <FaCode size={40}/>
        </Nav.Link>
        <Nav.Link className="music-nav-link" onClick={() => scrollToRef(musicRef)}>
          <FaMusic size={40}/>
        </Nav.Link>
        <Nav.Link className="contact-nav-link" onClick={() => scrollToRef(contactRef)}>
          <IoShareSocial size={40}/>
        </Nav.Link>
    </Nav>
  
  );
}
