import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaYoutube, FaSpotify } from 'react-icons/fa';
import { Tabs, Tab } from 'react-bootstrap';
import '../App.css';

export const Music = () => {
    return (
        <div className="jumbotron min-vh-100">
            <Container>
                <Row className='justify-content-left'>
                    <Col md={8}>
                        <h1>My Music! 🎹</h1>

                        <p>
                            I've been producing Hip-Hop/Trap, RnB, and Pop music for the past 4 years. 
                            It started off as me tinkering with garageband in high school, and has now become a particular passion of mine.

                        </p>

                        <Tabs
                            defaultActiveKey="youtube"
                            className="mb-3 music-tabs"
                            >
                            <Tab eventKey="youtube" title={<>Youtube <FaYoutube/></>}>
                                <div className="embed-responsive">
                                    <iframe 
                                        className="embed-responsive-item" 
                                        src="https://www.youtube.com/embed/sHGbivWOUDo" 
                                        allowFullScreen
                                        style={{ borderRadius: '15px', width: '100%', height: '352px' }}
        
                                    />
                                </div>
                            </Tab>
                            <Tab eventKey="spotify" title={<>Spotify <FaSpotify/></>} className='spotify-tab'>
                                <iframe
                                    src="https://open.spotify.com/embed/artist/4gtbYuWL3jPmNndg8H6bsW?utm_source=generator" 
                                    width="100%" 
                                    height="352" 
                                    allowFullScreen
                                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                                    loading="lazy"
                                    style={{ borderRadius: '15px' }}
                                />
                            </Tab>
                        </Tabs>

                        

                    </Col>
                </Row>
            </Container>
        </div>
    );
};