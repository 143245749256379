import logo from '../logo.svg';
import '../App.css';
import { Banner } from '../components/Banner';
import { Navigation } from '../components/Navigation';
import { Projects } from '../components/Projects';
import { Container, Row, Col } from 'react-bootstrap';
import { Music } from '../components/Music';
import { Contact } from '../components/Contact';
import { useRef } from 'react';

export default function Home() {

  const homeRef = useRef();
  const projectsRef = useRef();
  const musicRef = useRef();
  const contactRef = useRef();


  return (
    <div className='App' style={{backgroundColor: '#eee'}}>
        <Container fluid style={{paddingTop: '5vh'}}>

            <Row>
                <Col xs ={2} className='justify-content-center align-items-center no-padding d-none d-sm-flex' style={{maxHeight: '100vh'}}>
                    <Navigation  homeRef={homeRef} projectsRef={projectsRef} musicRef={musicRef} contactRef={contactRef} className='d-sm-none'/>  
                </Col>


                <Col  className='no-padding'>
                    <div ref={homeRef}>
                        <Banner />
                    </div>
                    <div ref={projectsRef} style={{paddingTop: '1rem'}}>
                        <Projects />
                    </div>
                    <div ref={musicRef} style={{paddingTop: '1rem'}}>
                        <Music />
                    </div >
                    <div ref={contactRef} style={{paddingTop: '1rem'}}>
                        <Contact />
                    </div>
                </Col>

                <Col xs ={2} className='justify-content-center align-items-center no-padding d-none d-sm-flex' style={{maxHeight: '100vh'}}>
                </Col>

            </Row>
        </Container>
    </div>
  );
}

