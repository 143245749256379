import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export const Banner = () => {
    return (
        <div className="jumbotron d-flex align-items-center min-vh-100">
            <Container>
                <Row className='justify-content-left'>
                    <Col sm={12}>
                        <h1>Hi there! 👋🏽</h1>
                        <p>I'm Pruthvi, a CS/Math 💻 major at UC Berkeley curious about the intersection of ML and Engineering. On the research 🧪 side,
                        I'm interested in applied deep learning. On the engineering ⚙️ side, I'm curious about the large-scale productionization of
                        intensive ML models. </p>

                        <br />

                        <p>
                            As a researcher 🥼 in the InterACT lab, I'm exploring different Reinforcement Learning algorithms towards medical devices used in
                            Parkinsons' patients. As a VP 👔 @ Big Data at Berkeley, a data consulting org, I source and oversee 3 unique 
                            industry projects across 25+ members.
                        </p>

                        <br />

                        <p>
                            Previously, I worked as an ML/Data Science Intern on the Intelligence 🧠 Team @ <span style={{color: '#F06A6A', fontWeight: 'bold'}}>Asana</span>, where I improved how users navigate projects. 
                            Prior to that, I worked in Fullstack Development @ <span style={{color: '#774EFF', fontWeight: 'bold'}}>Boombox</span> 🎶, to push several user-facing features that simplify music collaboration.
                        </p>

                        <br />

                        <p>
                            In my free time, you can find me producing music 🎹 in my bedroom till 3'am, running pickup 🏀 on the weekends, or exploring Berkeley's many restaurants 🍕🍽️ with some friends.
                        </p>


                    </Col>
                </Row>
            </Container>
        </div>
    );
};