import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export const Contact = () => {
    return (
        <div className="jumbotron d-flex align-items-center min-vh-100">
            <Container>
                <Row className='justify-content-left'>
                    <Col md={8}>
                        <h1>Contact Me! 📞</h1>
                        <p>
                            I'm always open to chat about anything! Feel free to reach out to me on any of the platforms below.
                        </p>
                        <br />
                        <p>
                            <a href="mailto: pruthvi.innamuri@berkeley.edu" target="_blank">Email</a> <br />
                            <a href="https://www.linkedin.com/in/pruthvi-innamuri/" target="_blank">LinkedIn</a> <br />
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};